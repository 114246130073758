import { authenticate } from './authenticate'
import { getTable, getItemExpander, getItemsFromIdsGetter, getItemFromSlugGetter, pickFirst } from '@mmd/domain-recovib/backoffice'
import { accountTable } from '@mmd/domain-recovib/account'
import { userTable, organisationTable, roleTable } from './'

// :TODO: declare an enum with errors
const throwError = (id) => {
  let msg
  switch (id) {
    case 'Backoffice error':
      msg = id
      break
    default:
      msg = 'Unknown Error'
  }
  throw new Error(msg)
}

export const expandAdmin = (backoffice, user, isAdmin) => {
  if (isAdmin) {
    if (user === 'ALL') {
      return { user, ...asSuperAdmin(backoffice) }
    } else {
      if (!user) {
        user = 'modyva'
      }
      return { user, ...asAccountAdmin(backoffice, user) }
    }
  }
}

export const asSuperAdmin = (backoffice) => {
  return {
    branding: 'micromega',
    permissions: 'affiliate',
    screenName: 'Micromega',
    role: 'mmd_root',
    accounts: getTable(backoffice, accountTable).items,
  }
}

const asUser = (backoffice, login) => {
  const getAffiliations = getItemsFromIdsGetter(backoffice, organisationTable)
  const getUser = getItemFromSlugGetter(backoffice, userTable)
  const getRoles = getItemsFromIdsGetter(backoffice, roleTable)
  const expandAccount = getItemExpander(backoffice, accountTable)

  const user = getUser(login)
  const role = pickFirst(getRoles(user.role))
  const { accounts: accountIds, affiliation: affiliationIds, permissions } = role
  const affiliation = pickFirst(getAffiliations(affiliationIds))
  if (!accountIds || !affiliationIds) {
    throwError('Backoffice error')
  }

  const accounts = accountIds.map(expandAccount)
  return {
    branding: affiliation.slug,
    screenName: user.screenName,
    permissions,
    accounts,
  }
}

export const asAccountAdmin = (backoffice, user) => asUser(backoffice, user)

export const asAuthenticatedUser = (backoffice, user, pass) => {
  const login = authenticate(user, pass)
  return asUser(backoffice, login)
}

export const validateUser = ({ user, permissions, accounts, screenName, branding }) => {
  const shared = { branding, screenName }
  return permissions
    ? {
        login: { user, authError: undefined },
        permissions,
        accounts,
        ...shared,
      }
    : {
        login: { user: undefined, authError: 'Your credentials are incorrect. Please Try Again' },
        permissions: undefined,
        accounts: [],
        ...shared,
      }
}

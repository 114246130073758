import * as anyStatement from './cosmos-statement'
import * as cosmos from './cosmos-store'
import * as anyFormatter from './formatter'
import { getUrlParam, getSubdomain } from '@mmd/io/window-location'

const isDump = (streamId) => {
  return streamId.includes('__dump') ? true : false
}

export const getStreamConfig = ({ streamId, timeRange }) => {
  // const isWeekly = isWeeklyTimeRange(timeRange)
  let config

  const statParam = getUrlParam('stat')

  // statements and formatter for signal type
  if (4 === 5) {
    // :INFO: Of course this will never be executed.
    // This is simply to have everything else nicely aligned
    // --------------------------------
    // -- archived ----------------
    // --------------------------------
  } else if (['cold-path__dump'].includes(streamId)) {
    config = { statement: anyStatement.dump(), formatter: anyFormatter.coldpath, partitionKey: '["99"]', ...cosmos.prodPr202 }
  } else if (['n11-1-vib__dump'].includes(streamId)) {
    config = { statement: anyStatement.dumpRetro(), formatter: anyFormatter.vibRetro, partitionKey: '["11"]', ...cosmos.prodPr202 }
  } else if (['n12-1-vib__dump', 'thp-2-vib__dump', 'thp-1-vib__dump'].includes(streamId)) {
    // 18600010 "name": "12A21#12A22",
    config = { statement: anyStatement.dumpRetro(), formatter: anyFormatter.vibRetro, partitionKey: '["12"]', ...cosmos.prodPr202 }
  } else if (['n13-1-vib__dump'].includes(streamId)) {
    config = { statement: anyStatement.dumpRetro(), formatter: anyFormatter.vibRetro, partitionKey: '["13"]', ...cosmos.prodPr202 }
  } else if (['n18-1-vib__dump'].includes(streamId)) {
    config = { statement: anyStatement.dumpRetro(), formatter: anyFormatter.vibRetro, partitionKey: '["18"]', ...cosmos.prodPr202 }
    // --------------------------------
    // -- disconnected ----------------
    // --------------------------------
  } else if (['MIMD_0007'].includes(streamId)) {
    // MIMD_0007  "name": "6A10",
    config = { statement: anyStatement.dumpRetro(), formatter: anyFormatter.vibRetro, partitionKey: '["6"]', ...cosmos.prodPr202 }
  } else if (['tst-1-vib'].includes(streamId)) {
    config = { statement: anyStatement.monitor('TBD'), formatter: anyFormatter.multiApp, ...cosmos.dbMain }

    // --------------------------------
    // -- vibRetro ----------------
    // --------------------------------
  } else if (['lho-1-vib', 'demo-1-vib', 'lho-1-vib__dump', 'demo-1-vib__dump'].includes(streamId)) {
    const firstDate = new Date('2020-02-24T23:00:00.000Z')
    const startDate = new Date(timeRange.start)
    if (startDate > firstDate) {
      // console.log('--MLA--')
      // dbMain, vib, 18600012 "name": "10A16#10A17",
      config = {
        statement: isDump(streamId) ? anyStatement.dump('10A') : anyStatement.monitor('10A16'),
        formatter: anyFormatter.multiApp,
        ...cosmos.dbMain,
      }
    } else {
      // PR-202, vibRetro
      console.log('--PR-202--')
      config = {
        statement: isDump(streamId) ? anyStatement.dumpRetro() : anyStatement.vibRetro,
        formatter: anyFormatter.vibRetro,
        partitionKey: '["10"]',
        ...cosmos.prodPr202,
      }
    }
    // --------------------------------
    // -- vibration ----------------
    // --------------------------------
  } else if (['pom-1-vib', 'pom-1-vib__dump'].includes(streamId)) {
    config = {
      statement: isDump(streamId) ? anyStatement.dump('Railways') : anyStatement.monitor('Railways'),
      formatter: anyFormatter.multiApp,
      ...cosmos.dbMain,
      name: 'Railways',
    }
  } else if (['nyh-1-acc', 'nyh-1-acc__dump'].includes(streamId)) {
    config = {
      statement: anyStatement.monitor('steam0|steam1|steam2|steam3', statParam || 'rms'), // steam|equal|chair|cargo
      formatter: anyFormatter.multiApp,
      partitionKey: '["99"]',
      ...cosmos.dbEastUS,
    }
  } else if (['nyh-1-bst', 'nyh-1-bst__dump'].includes(streamId)) {
    config = {
      statement: anyStatement.burstTable('cargo'),
      formatter: anyFormatter.burstTable,
      partitionKey: '["99"]',
      ...cosmos.dbEastUS,
    }
  } else if (['nyh-1-acc-staging', 'nyh-1-acc-staging__dump'].includes(streamId)) {
    config = {
      statement: anyStatement.monitor('steam0|steam1|steam2|steam3', 'peak'), // steam|equal|//      statement: anyStatement.monitor('crazy0|crazy1|crazy2|crazy3'), // steam|equal|chair|cargo
      formatter: anyFormatter.multiApp,
      ...cosmos.dbStaging,
    }
  } else if (['nyh-1-bst-staging', 'nyh-1-bst-staging__dump'].includes(streamId)) {
    config = {
      statement: anyStatement.burstTable('goofy'),
      formatter: anyFormatter.burstTable,
      ...cosmos.dbStaging,
    }
  } else if (['cem-1-vib', 'cem-1-vib__dump'].includes(streamId)) {
    config = {
      statement: isDump(streamId) ? anyStatement.dump('femur') : anyStatement.monitor('femur'),
      formatter: anyFormatter.multiApp,
      ...cosmos.dbWeuNinja,
    }
  } else if (['vmv-1-vib'].includes(streamId)) {
    config = { statement: anyStatement.monitor('worry'), formatter: anyFormatter.multiApp, ...cosmos.dbSeasiaFable }
  } else if (['tvc-1-vib', 'tvc-1-vib__dump'].includes(streamId)) {
    config = {
      statement: isDump(streamId) ? anyStatement.dump('agony1') : anyStatement.monitor('agony1', 'rms'),
      formatter: anyFormatter.multiApp,
      ...cosmos.dbStaging,
    }
  } else if (['tvc-2-vib', 'tvc-2-vib__dump'].includes(streamId)) {
    config = {
      statement: isDump(streamId) ? anyStatement.dump('agony2') : anyStatement.monitor('agony2', 'rms'),
      formatter: anyFormatter.multiApp,
      ...cosmos.dbStaging,
    }
  } else if (['tvc-3-vib', 'tvc-3-vib__dump'].includes(streamId)) {
    config = {
      statement: isDump(streamId) ? anyStatement.dump('agony3') : anyStatement.monitor('agony3', 'rms'),
      formatter: anyFormatter.multiApp,
      ...cosmos.dbStaging,
    }
  } else if (['tvc-4-vib', 'tvc-4-vib__dump'].includes(streamId)) {
    config = {
      statement: isDump(streamId) ? anyStatement.dump('agony4') : anyStatement.monitor('agony4', 'rms'),
      formatter: anyFormatter.multiApp,
      ...cosmos.dbStaging,
    }
    // --------------------------------
    // -- Air Pressure ----------------
    // --------------------------------
  } else if (['lho-2-air', 'demo-2-air', 'lho-2-air__dump', 'demo-2-air__dump'].includes(streamId)) {
    config = { statement: isDump(streamId) ? anyStatement.dump('Air fast') : anyStatement.monitor('Air fast'), formatter: anyFormatter.singleChannel }
    config = { ...config, ...cosmos.dbMain }

    // --------------------------------
    // -- Dust  ----------------
    // --------------------------------
  } else if (['lho-3-dst', 'demo-3-dst', 'lho-3-dst__dump', 'demo-3-dst__dump'].includes(streamId)) {
    config = { statement: isDump(streamId) ? anyStatement.dump('nanny') : anyStatement.monitor('nanny'), formatter: anyFormatter.multiApp }
    config = { ...config, ...cosmos.dbMain }
  }

  if (config && config.statement && config.formatter && config.db) {
    const { statement, formatter, partitionKey, host, db, mastKey, collection } = config
    const query = statement(timeRange)
    return {
      query,
      formatter,
      cosmosStore: {
        host,
        db,
        collection,
        mastKey,
        partitionKey,
      },
    }
  } else {
    console.warn('[MissingConfig]', streamId, timeRange)
    throw new Error(`Missing config: ${streamId},  ${timeRange}`)
    console.warn(`[StreamConfigs] config is incomplete`, config)
  }
}

export const lensConfig = (slugs) => {
  const showEvents = slugs.includes('lho-1-vib') || slugs.includes('demo-1-vib') || slugs.includes('cem-1-vib') ? true : false

  const showBursts =
    slugs.includes('lho-1-vib') ||
    slugs.includes('demo-1-vib') ||
    slugs.includes('tvc-1-vib') ||
    slugs.includes('tvc-2-vib') ||
    slugs.includes('tvc-3-vib') ||
    slugs.includes('tvc-4-vib')
      ? true
      : false
  const showWeeks = showEvents || slugs.includes('lho-2-air') || slugs.includes('nyh-1-acc') ? false : true

  const eventMin = slugs.includes('cem-1-vib') ? 5 : 1

  const eventUnit = slugs.includes('lho-1-vib') || slugs.includes('demo-1-vib') ? 'VB2' : 'Peak'

  const eventLegend = slugs.includes('lho-1-vib') || slugs.includes('demo-1-vib') ? 'VB2 on X|Y|Z' : 'Peak'

  let burstTemporalTitle, burstTemporalLegend
  if (slugs.includes('lho-1-vib') || slugs.includes('demo-1-vib')) {
    burstTemporalTitle = 'Burst Peak Particle Velocity (PPV) in mm/s'
    burstTemporalLegend = 'PPV (mm/s)'
  } else {
    burstTemporalTitle = 'Burst acceleration in g'
    burstTemporalLegend = 'Acceleration (g)'
  }

  return { showEvents, showBursts, showWeeks, eventMin, eventUnit, eventLegend, burstTemporalTitle, burstTemporalLegend }
}

export const streamConfig = (slug) => {
  const zoomParam = getUrlParam('zoom')
  const ymaxParam = parseFloat(getUrlParam('ymax'))
  const ylineParam = parseFloat(getUrlParam('yline'))

  let xZoom
  if (zoomParam) {
    const dt = new Date(zoomParam).getTime()
    xZoom = {
      min: dt - 900000,
      max: dt + 900000,
    }
  }
  let overwrites = {}
  if (slug === 'cem-1-vib') {
    overwrites = { ymax: 16, maxStd: 15 }
  } else if (['tvc-1-vib', 'tvc-2-vib', 'tvc-3-vib', 'tvc-4-vib'].includes(slug)) {
    overwrites = { ymax: 200, maxStd: 180 }
  } else if (['nyh-1-acc'].includes(slug)) {
    overwrites = { ymax: ymaxParam || 0.5, maxStd: ylineParam || 0.25, xZoom, statTitle: 'RMS Acceleration (g)', statUnit: '' }
  } else if (['nyh-21-acc-burst-1'].includes(slug)) {
    overwrites = {}
  }
  return overwrites
}

export const hasSafeLevels = (streams = []) => {
  const streamSlugs = streams.map((d) => d.slug)
  return streamSlugs.includes('tvc-1-vib', 'tvc-2-vib', 'tvc-3-vib', 'tvc-4-vib') ? false : true
}

export const authenticate = (user, pass) => {
  if (user === 'Modyva' && pass === 'mod091118*') {
    user = 'modyva'
  } else if (user === 'Lhoist' && pass === 'mod091118*') {
    user = 'lhoist'
  } else if (user === 'Pommeroeul' && pass === 'Vobox*60') {
    user = 'pommeroeul'
  } else if (user === 'gantrex' && pass === '$wiftLock47') {
    user = 'gantrex'
  } else if (user === 'cementys' && pass === 'Nemav*50') {
    user = 'cementys'
  } else if (user === 'vmvtech' && pass === 'Ridit$57') {
    user = 'vmvtech'
  } else if (user === 'demo' && pass === 'demo') {
    user = 'demo'
  } else {
    // user = undefined
  }
  return user
}

import { getAccountSecretUrl } from '@mmd/domain-recovib/account'

const getFirstStream = (activeLens) => {
  if (!activeLens) {
    return
  }
  const { streams = [] } = activeLens || {}
  if (streams.length !== 1) {
    const slugs = (streams || []).map((d) => d.slug)
    console.warn('[WARN] we do not have the expected one stream', slugs)
  }
  return streams[0]
}

export const getFirstStreamSlug = (activeLens) => {
  return (getFirstStream(activeLens) || {}).slug
}

export const getFirstStreamApps = (activeLens) => {
  if (!activeLens) {
    return
  }
  const fst = getFirstStream(activeLens)
  const config = JSON.parse(fst.config)
  const app = config.app || {}
  return { beat: app.beat || config.name, burst: app.burst || config.name }
}

export const getStreamUrl = (getUuid, activeLens) => {
  if (!activeLens) {
    return
  }
  const tenantSecretUrl = getAccountSecretUrl(getUuid, activeLens)
  const streamSlug = getFirstStreamSlug(activeLens)
  return `${tenantSecretUrl}/${streamSlug}`
}

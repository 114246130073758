export const dbEastUS = {
  host: 'recovib-prod-eastus-smoky-db',
  db: 'recovib-prod-eastus-smoky-db',
  mastKey: '5T9VBG7DUNiF255j3zwrAEmYDaLUv5gnLFObEu3KscsSLpH1oQVUL2EvKgMMKYLCUO0UPBRbJ3x3JRX1TUbSGg==',
  collection: 'production',
}

export const dbWeuNinja = {
  host: 'recovib-prod-westeu-ninja-db',
  db: 'recovib-prod-westeu-ninja-db',
  mastKey: 'qOVTcfxhSRghYWCDdzC3JANCLcYbx4OiRDkGUX2qELa9yY0sV3kRvkZXOc4WrklEm26mvbU86T4N0dqjU4IB7w==',
  collection: 'production',
}

export const dbSeasiaFable = {
  host: 'recovib-prod-seasia-fable-db',
  db: 'recovib-prod-seasia-fable-db',
  mastKey: 'GgIw1I6ZXrGwCW6ELfUhV8dE3xPJbGsA3Dj9Ot13xVf8c40EPEWDBI2cZ28pd7xpoJ0ksqQtDiBvJmFyYGlCBw==',
  collection: 'production',
}

export const dbMain = {
  host: 'db-main',
  db: 'db-main',
  mastKey: 'zceSSKpoUOGrbLD132oSqI0qAzjxOHvNZIxilB2MNZzDuwlqC1OB62SHCZksAkai8jCVFVkKV09dFcbe85r1NA==',
  collection: 'production',
}

export const dbStaging = {
  host: 'recovib-stag-france-study-db-freetier',
  db: 'recovib-stag-france-study-db-freetier',
  mastKey: '2irFAJ2tnBs29BHCsoYgM1RDuwyvrqpbwSzSTcN1XtukW2rW63nxiddynyF3rnRW63LV4giafhuCMJ9VrpW9Cg==',
  collection: 'staging',
}

export const prodPr202 = {
  host: 'db-pr-202-01',
  db: 'pr-202-01-db',
  mastKey: 'TGaAlAwFpe0z8bLpicyxQ6w2HXfc9M6Y0t0Kycs6c09xXpLbdglo5kp8xNasItLtBJqEp5Y7tb9iaryEgy1spw==',
  collection: 'blastinghistory',
}

export const table = [
  {
    "slug": "stephane_thijs",
    "email": "s.thijs@thomas-piron.eu",
    "i18n": "Stephane Thijs",
    "status": "inactive",
    "_id": "recokuN9wwmo7lzkk"
  },
  {
    "slug": "Justine Baudoux",
    "email": "j.baudoux@thomas-piron.eu",
    "i18n": "Justine Baudoux",
    "status": "inactive",
    "_id": "reclKBqo0Q2L93pNW"
  },
  {
    "slug": "christophe_promper",
    "email": "cpromper@chpconsult.be",
    "i18n": "Christophe Promper",
    "status": "inactive",
    "_id": "recpa3aUasiyTlZvh"
  },
  {
    "slug": "Christian Jodard",
    "email": "christian.jodard@lhoist.com",
    "i18n": "Christian Jodard",
    "role": [
      "recQr4jMHjWenG7Un",
      "recWarXxEtjfVOOid"
    ],
    "phone": "32 (0)474 42 35 27",
    "status": "inactive",
    "_id": "recuBGyli9OHQck0n"
  },
  {
    "slug": "Jon Vansant",
    "email": "jon.vansant@vasantengineering.com",
    "i18n": "Jon Vansant",
    "status": "inactive",
    "_id": "recdGqAOdPGJ5b9ZT"
  },
  {
    "slug": "mmd_root",
    "i18n": "mmd_root",
    "role": [
      "recXRlHaY9YzIQxRB",
      "rectzg796uRxA5cwe"
    ],
    "status": "active",
    "url_latest": "https://mmd.recovib.io/?role=admin&user=ALL",
    "url_production": "https://mmd.recovib.io/",
    "_id": "recLhnEbhncBQj5fA"
  },
  {
    "slug": "demo",
    "i18n": "demo",
    "role": [
      "recSdWBzCf0jP2Jft"
    ],
    "pass": "demo",
    "status": "active",
    "url_latest": "https://demo.recovib.io/?role=admin&user=demo",
    "url_production": "https://demo.recovib.io/",
    "_id": "recBFifocSq4fCSD1"
  },
  {
    "slug": "modyva",
    "i18n": "Modyva",
    "role": [
      "recPBUdz726z0L5up"
    ],
    "pass": "mod091118*",
    "status": "active",
    "url_latest": "https://staging.recovib.io/modyva-v-1-2-0-RC-2/?role=admin&user=modyva",
    "url_production": "http://modyva.recovib.io/",
    "_id": "reckRR73LtcRDabZW"
  },
  {
    "slug": "lhoist",
    "i18n": "Lhoist",
    "role": [
      "recoo5j79K6YnGNAu"
    ],
    "pass": "mod091118*",
    "status": "active",
    "url_latest": "https://staging.recovib.io/modyva-v-1-2-0-RC-2/?role=admin&user=lhoist",
    "url_production": "http://modyva.recovib.io/",
    "_id": "recP5J5z1roKrDmE4"
  },
  {
    "slug": "gantrex",
    "i18n": "Gantrex",
    "role": [
      "recY9m1v4Y63e8S2b"
    ],
    "pass": "$wiftLock47",
    "status": "active",
    "url_latest": "https://staging.recovib.io/gantrex-20200122-1/?role=admin&user=gantrex",
    "_id": "recb2AZelansV8RDx"
  },
  {
    "slug": "flow_engineering",
    "i18n": "Flow Engineering",
    "role": [
      "recthVkzxRKuhuURV"
    ],
    "status": "active",
    "_id": "recyxxyvvx2iTnCjc"
  },
  {
    "slug": "pommeroeul",
    "i18n": "Ville Pommeroeul",
    "role": [
      "rectzg796uRxA5cwe"
    ],
    "pass": "Vobox*60",
    "status": "active",
    "url_latest": "https://mmd.recovib.io/?role=admin&user=pommeroeul",
    "url_production": "https://mmd.recovib.io/",
    "_id": "rec05RruWRMH1hRcV"
  },
  {
    "slug": "cementys",
    "email": "amaurykitouni@sensoptix.com",
    "i18n": "cementys",
    "role": [
      "rec8Mi25ePSpIMCLH"
    ],
    "phone": "+33 6 52 41 56 33",
    "pass": "Nemav*50",
    "status": "active",
    "url_latest": "https://cementys.recovib.io/?role=admin&user=cementys",
    "url_production": "https://cementys.recovib.io/",
    "_id": "recUzJbaamSrvyw3W"
  },
  {
    "slug": "vmvtech",
    "i18n": "vmvtech",
    "role": [
      "recGxH6MSIpXrcFEQ"
    ],
    "pass": "Ridit$57",
    "status": "active",
    "url_latest": "https://staging.recovib.io/vmv-20200225-1/?role=admin&user=vmvtech",
    "url_production": "https://vmvtech.recovib.io/",
    "_id": "recZ2HZo8Rx44Cza4"
  }
]
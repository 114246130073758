export const table = [
  {
    "slug": "algo:tmd-efficiency",
    "tags": [
      "trigger:tmd"
    ],
    "i18n": "Dedicated algorithm for Tuned Mass Damper (TMD) efficiency computation.",
    "category": [
      "algo"
    ],
    "data-sources": [
      "recvl7jdalep3Cw7u"
    ],
    "data_stream copy": "wnl-3-tmd",
    "_id": "recGty6pWOEu8NKeh"
  },
  {
    "slug": "aq:monitor-4",
    "i18n": "Monitor-4",
    "category": [
      "acquisition"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "recsaH6D2ocUSwvQI",
      "recXuyEw5OlDdazIZ",
      "recFUeInK46msgfEe",
      "recbSjhmrGHnwgfpU",
      "recoTsCfw74ji6BRg",
      "reczAT4uvhwhqiQKc",
      "recxQ5BdtE6bYjO2N",
      "recr6Y8Yp7AfZWnYr",
      "recM3LQrkdidarFXy",
      "recuCoGADd1kv17cP",
      "recI9Xja68ji6VCzZ"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst, mbh-1-pot, mbh-12-tem, wnd-1-acc, coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd, mbh-2-pot, mbh-3-pot, mbh-14-tem, mbh-15-tem",
    "_id": "rec1A5t5HuKIYACFU"
  },
  {
    "slug": "aq:monitor-8",
    "i18n": "Monitor-8",
    "category": [
      "acquisition"
    ],
    "data-sources": [
      "recERx3ECNc8ToSKm",
      "rec2l1ben6EFobl73",
      "recvl7jdalep3Cw7u",
      "recjOVrHfGx4WT4Li",
      "recHL98cCay1CEJmC",
      "recOAO9p85XFURlyl",
      "rec5cd7To0UidPqow",
      "reccMHc7B8ZvdQ73q",
      "reca07kdlw6aXQ6Gv",
      "recdYRXMH0yeZoC8B",
      "recCkmJaC9zbJpGPf",
      "recsOpZQu5BOOQ7tv",
      "recupOFUOqXIO08o2",
      "rec9AgBsXNRy5DdtD",
      "recDoeSx8kCpHl9sP",
      "recmlRi4kNqzG0FJa",
      "recQEp2a9n7EWbtKK",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-2-air, nyh-1-vib, nyh-21-acc, demo-2-air, ecv-1-vib, wnl-3-tmd, gsm-1-tem, pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc",
    "_id": "recq1bAZDdUNGzdqr"
  },
  {
    "slug": "aq:monitor-8-i",
    "i18n": "Monitor-8-I",
    "category": [
      "acquisition"
    ],
    "_id": "recmwlgU2BbWUeVVE"
  },
  {
    "slug": "aq:recovib-blasting",
    "i18n": "Recovib.Blasting",
    "category": [
      "acquisition"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "reco3oDXuWSWU6qPO",
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "recvu5jV57eospwDA",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, pom-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib",
    "_id": "recHnZlNSSqkbU2dj"
  },
  {
    "slug": "cm:4g-board",
    "i18n": "4G Board",
    "category": [
      "comm"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "reco3oDXuWSWU6qPO",
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "recsaH6D2ocUSwvQI",
      "recXuyEw5OlDdazIZ",
      "recFUeInK46msgfEe",
      "recbSjhmrGHnwgfpU",
      "recoTsCfw74ji6BRg",
      "reczAT4uvhwhqiQKc",
      "recjOVrHfGx4WT4Li",
      "recHL98cCay1CEJmC",
      "recr6Y8Yp7AfZWnYr",
      "recM3LQrkdidarFXy",
      "recuCoGADd1kv17cP",
      "recI9Xja68ji6VCzZ",
      "recOAO9p85XFURlyl",
      "rec5cd7To0UidPqow",
      "reccMHc7B8ZvdQ73q",
      "reca07kdlw6aXQ6Gv",
      "recdYRXMH0yeZoC8B",
      "recCkmJaC9zbJpGPf",
      "recsOpZQu5BOOQ7tv",
      "recvu5jV57eospwDA",
      "recxQ5BdtE6bYjO2N",
      "recupOFUOqXIO08o2",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDoeSx8kCpHl9sP",
      "recmlRi4kNqzG0FJa",
      "recQEp2a9n7EWbtKK",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, nyh-1-vib, pom-1-vib, nyh-21-acc, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib, ecm-1-vib, ecm-2-dst, mbh-1-pot, mbh-12-tem, gsm-1-tem, wnd-1-acc, coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd, mbh-2-pot, mbh-3-pot, mbh-14-tem, mbh-15-tem, pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc",
    "_id": "recHHszEdfubcrfRO"
  },
  {
    "slug": "cm:wifi",
    "i18n": "Wifi",
    "category": [
      "comm"
    ],
    "data-sources": [
      "recr6Y8Yp7AfZWnYr",
      "recM3LQrkdidarFXy",
      "recuCoGADd1kv17cP",
      "recI9Xja68ji6VCzZ"
    ],
    "data_stream copy": "coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd",
    "_id": "recHNZ4OVHIGxWufv"
  },
  {
    "slug": "conn:i1-i1-i1-i1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recr6Y8Yp7AfZWnYr",
      "recM3LQrkdidarFXy",
      "recuCoGADd1kv17cP",
      "recI9Xja68ji6VCzZ"
    ],
    "data_stream copy": "coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd",
    "_id": "recXNo4xuhS64bnmu"
  },
  {
    "slug": "conn:i2-i2-i2-i2",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recOAO9p85XFURlyl",
      "rec5cd7To0UidPqow",
      "reccMHc7B8ZvdQ73q",
      "reca07kdlw6aXQ6Gv",
      "recdYRXMH0yeZoC8B",
      "recCkmJaC9zbJpGPf",
      "recsOpZQu5BOOQ7tv"
    ],
    "data_stream copy": "pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc",
    "_id": "recWXNLZ4FJYaIHQ7"
  },
  {
    "slug": "conn:i3",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "recHL98cCay1CEJmC"
    ],
    "data_stream copy": "thp-1-vib, thp-2-vib, gsm-1-tem",
    "_id": "recBVp97aEfg8CuuI"
  },
  {
    "slug": "conn:i3-i1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "recfo8It2xJGQng92"
  },
  {
    "slug": "conn:i3-icp1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recRjuilDzlaQJAc5"
  },
  {
    "slug": "conn:ud3-u1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "recCxOSD5camzVnSc"
  },
  {
    "slug": "host:pr202",
    "tags": [
      "ix:hosted-app"
    ],
    "i18n": "https://pr-202-01.azurewebsites.net/",
    "category": [
      "deployment"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "rec2l1ben6EFobl73",
      "recvl7jdalep3Cw7u",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, thp-1-vib, thp-2-vib, ecv-1-vib, wnl-3-tmd",
    "_id": "recBHOV38yf9MhtDq"
  },
  {
    "slug": "host:recovib-cloud-deprecated",
    "tags": [
      "ix:hosted-app"
    ],
    "i18n": "Recovib-monitoring cloud platform (deprecated)",
    "category": [
      "deployment"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu"
    ],
    "Deprecated": true,
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "recCfY5CyrjFblPfD"
  },
  {
    "slug": "host:recovib.io",
    "tags": [
      "ix:hosted-app"
    ],
    "i18n": "http://recovib.io",
    "category": [
      "deployment"
    ],
    "data-sources": [
      "recERx3ECNc8ToSKm",
      "recjOVrHfGx4WT4Li",
      "recr6Y8Yp7AfZWnYr",
      "recM3LQrkdidarFXy",
      "recuCoGADd1kv17cP",
      "recI9Xja68ji6VCzZ",
      "recvu5jV57eospwDA",
      "rec5xcVRiCs8Xplfk",
      "recIWnsFY59I0t6eq",
      "rec9XkV14TJZFC38e",
      "recupOFUOqXIO08o2",
      "rec9AgBsXNRy5DdtD",
      "recDoeSx8kCpHl9sP",
      "recmlRi4kNqzG0FJa",
      "recQEp2a9n7EWbtKK",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-2-air, nyh-1-vib, pom-1-vib, mbh-24-ac, nyh-21-acc, demo-2-air, coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd, mbh-22-ac, mbh-23-ac",
    "_id": "recxKPXIHR7OtcW49"
  },
  {
    "slug": "in:1-axis-cm-accelero",
    "tags": [
      "type:1-axis"
    ],
    "i18n": "1-axis CM accelerometer",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recr6Y8Yp7AfZWnYr",
      "recM3LQrkdidarFXy",
      "recuCoGADd1kv17cP",
      "recI9Xja68ji6VCzZ"
    ],
    "data_stream copy": "coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd",
    "_id": "recTxw6eotonR8YK7"
  },
  {
    "slug": "in:1-axis-iac-i-accelero",
    "tags": [
      "type:1-axis"
    ],
    "i18n": "standard IAC-I 1-axis accelerometer",
    "category": [
      "sensor"
    ],
    "_id": "recvk9Q3mLoemXQ7O"
  },
  {
    "slug": "in:2-axis-iac-i-accelero",
    "tags": [
      "type:2-axis"
    ],
    "i18n": "IAC-I 2-axis accelerometer",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recOAO9p85XFURlyl",
      "rec5cd7To0UidPqow",
      "reccMHc7B8ZvdQ73q",
      "reca07kdlw6aXQ6Gv",
      "recdYRXMH0yeZoC8B",
      "recCkmJaC9zbJpGPf",
      "recsOpZQu5BOOQ7tv",
      "recNuKOwJ7WfmcyXy",
      "rec5xcVRiCs8Xplfk",
      "recIWnsFY59I0t6eq",
      "rec9XkV14TJZFC38e"
    ],
    "data_stream copy": "mbh-24-ac, pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc, mbh-21-ac, mbh-22-ac, mbh-23-ac",
    "_id": "recubEWVnHVEGJX3z"
  },
  {
    "slug": "in:3-axis-hres-i-accelero",
    "tags": [
      "type:3-axis",
      "type:multi-series",
      "trigger:accelero"
    ],
    "i18n": "3-axis HiRes I accelerometer ",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "reco3oDXuWSWU6qPO",
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "rech9XerLoitdmkRP",
      "rec2l1ben6EFobl73",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib, ecm-1-vib, ecv-1-vib",
    "_id": "rec5C7qLS5TqNTf21"
  },
  {
    "slug": "in:3-axis-hres-ud-accelero",
    "tags": [
      "type:3-axis",
      "type:multi-series",
      "trigger:accelero"
    ],
    "i18n": "3-axis HiRes Ud accelerometer",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "reco3oDXuWSWU6qPO",
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "recvu5jV57eospwDA",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, pom-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib",
    "_id": "recvldM9fR51q177e"
  },
  {
    "slug": "in:air-pressure-infrasound-mic-2-250hz",
    "tags": [
      "trigger:air-pressure"
    ],
    "i18n": "Air Pressure Microphone (infrasound from 2Hz to 250Hz).",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recERx3ECNc8ToSKm",
      "rec9AgBsXNRy5DdtD"
    ],
    "data_stream copy": "lho-2-air, demo-2-air",
    "_id": "reccfvGuXmEQtb4Zb"
  },
  {
    "slug": "in:dust-meter",
    "tags": [
      "trigger:dust"
    ],
    "i18n": "Casella dust meter with U/I external converter ",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recOLVd6kEzXhNhBu"
    ],
    "data_stream copy": "ecm-2-dst",
    "_id": "recEty49PcIU3KrHd"
  },
  {
    "slug": "in:high-res-sns",
    "tags": [
      "trigger:sns"
    ],
    "i18n": "HiRes Structural Noise Sensor",
    "category": [
      "sensor"
    ],
    "_id": "recwTARvPt5s7E15W"
  },
  {
    "slug": "in:inclinometer",
    "tags": [
      "trigger:slope"
    ],
    "i18n": "inclinometer (without temperature compensation). ",
    "category": [
      "sensor"
    ],
    "_id": "recsJl9XwXvGaqH4p"
  },
  {
    "slug": "in:potentiometer-sensor",
    "tags": [
      "trigger:voltage"
    ],
    "i18n": "String Potentiometer",
    "category": [
      "sensor"
    ],
    "_id": "recRMkOkE4T4xSgLZ"
  },
  {
    "slug": "in:temperature-sensor",
    "tags": [
      "trigger:temperature"
    ],
    "i18n": "Temperature Sensor",
    "category": [
      "sensor"
    ],
    "_id": "recMazofw2DBIZEHf"
  },
  {
    "slug": "info:alert-alarm-email",
    "tags": [
      "ix:email",
      "type:alarm",
      "ix:alert"
    ],
    "i18n": "Alarm email when acceleration exceed a given level in order to stop work. ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "rec2l1ben6EFobl73",
      "recvl7jdalep3Cw7u",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, ecv-1-vib, wnl-3-tmd",
    "_id": "recDhAvQ8ELGeSemH"
  },
  {
    "slug": "info:alert-alarm-sms",
    "tags": [
      "ix:sms",
      "type:alarm",
      "ix:alert"
    ],
    "i18n": "Alarm SMS when acceleration exceed a given level in order to stop work. ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "rec2l1ben6EFobl73",
      "recvl7jdalep3Cw7u",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, ecv-1-vib, wnl-3-tmd",
    "_id": "recj8FWZ78SQh6J2P"
  },
  {
    "slug": "info:alert-burst-datapoints",
    "tags": [
      "ix:alert",
      "type:burst-data"
    ],
    "i18n": "Periodic bursts for FFT computations ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "recvl7jdalep3Cw7u",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, wnl-3-tmd",
    "_id": "recSEAzZVY930Oiya"
  },
  {
    "slug": "info:alert-warning-email",
    "tags": [
      "ix:email",
      "type:warning",
      "ix:alert"
    ],
    "i18n": "Warning email when acceleration exceed a given level in order to take actions ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "recvl7jdalep3Cw7u",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, wnl-3-tmd",
    "_id": "recFPBVxJiksZZNok"
  },
  {
    "slug": "info:alert-warning-sms",
    "tags": [
      "ix:sms",
      "type:warning",
      "ix:alert"
    ],
    "i18n": "Warning SMS when acceleration exceed a given level in order to take actions ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "rec2l1ben6EFobl73",
      "recvl7jdalep3Cw7u",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, ecv-1-vib, wnl-3-tmd",
    "_id": "recCadj8O6EUp9coB"
  },
  {
    "slug": "pow:pv-power-supply",
    "i18n": " PV power supply to avoid crossing cables",
    "category": [
      "power"
    ],
    "data-sources": [
      "recsaH6D2ocUSwvQI",
      "recXuyEw5OlDdazIZ",
      "recFUeInK46msgfEe",
      "recbSjhmrGHnwgfpU",
      "recoTsCfw74ji6BRg",
      "reczAT4uvhwhqiQKc"
    ],
    "data_stream copy": "mbh-1-pot, mbh-12-tem, mbh-2-pot, mbh-3-pot, mbh-14-tem, mbh-15-tem",
    "_id": "recohzdIcdQbEdGbS"
  },
  {
    "slug": "risk:building-damage",
    "i18n": "Building Damage",
    "category": [
      "risk"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recVjDucqFnmaRfkt"
  },
  {
    "slug": "risk:sensitive-equipment",
    "tags": [
      "config:sensitive"
    ],
    "i18n": "Sensitive Equipment",
    "category": [
      "risk"
    ],
    "data-sources": [
      "reco3oDXuWSWU6qPO",
      "rec2l1ben6EFobl73"
    ],
    "data_stream copy": "bie-1-vib, ecv-1-vib",
    "_id": "rec6LcuWGiJRQliZo"
  },
  {
    "slug": "risk:work-of-art",
    "tags": [
      "config:sensitive"
    ],
    "i18n": "Work of Art",
    "category": [
      "risk"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "rec0dqfeM5fsr8Oh7"
  },
  {
    "slug": "sd:air-pressure-35pa",
    "tags": [
      "threshold:35pa",
      "trigger:air-pressure"
    ],
    "i18n": "Air pressure threshold at 35Pa",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recERx3ECNc8ToSKm",
      "rec9AgBsXNRy5DdtD"
    ],
    "data_stream copy": "lho-2-air, demo-2-air",
    "_id": "recMLuhzWct4sjpHA"
  },
  {
    "slug": "sd:dust-x",
    "tags": [
      "trigger:dust"
    ],
    "i18n": "Dust",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recOLVd6kEzXhNhBu",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "ecm-2-dst",
    "_id": "recpJJH7DdQM45pWf"
  },
  {
    "slug": "sd:potentiometer-level",
    "tags": [
      "trigger:voltage"
    ],
    "i18n": "Potentiometer Level",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recsaH6D2ocUSwvQI",
      "recXuyEw5OlDdazIZ",
      "recFUeInK46msgfEe"
    ],
    "data_stream copy": "mbh-1-pot, mbh-2-pot, mbh-3-pot",
    "_id": "recGbzftZNWKUt9wm"
  },
  {
    "slug": "sd:sns",
    "tags": [
      "trigger:sns"
    ],
    "i18n": "SNS",
    "category": [
      "standard"
    ],
    "_id": "recrtQ5RL5BU8jqr0"
  },
  {
    "slug": "sd:temperature-level",
    "tags": [
      "trigger:temperature"
    ],
    "i18n": "Temperature Level",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recbSjhmrGHnwgfpU",
      "recoTsCfw74ji6BRg",
      "reczAT4uvhwhqiQKc"
    ],
    "data_stream copy": "mbh-12-tem, mbh-14-tem, mbh-15-tem",
    "_id": "recfiKh7VfghhQgx9"
  },
  {
    "slug": "sd:tmd-efficiency",
    "tags": [
      "trigger:tmd"
    ],
    "i18n": "TMD efficiency",
    "category": [
      "standard"
    ],
    "_id": "rec6Wi1msJMeGluyJ"
  },
  {
    "slug": "sd:vib-din4150-3-L2",
    "tags": [
      "threshold:din4150",
      "trigger:vibration"
    ],
    "i18n": "Monitoring Building Vibration according to DIN4150-3/L2 (normal buildings)",
    "category": [
      "standard"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recLV3zmV5oGx9ZYT",
      "recvu5jV57eospwDA",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, pom-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, thp-1-vib",
    "_id": "rec1Eh4y1l9HdxKJb"
  },
  {
    "slug": "sd:vib-din4150-3-L3",
    "tags": [
      "threshold:din4150",
      "trigger:vibration"
    ],
    "i18n": "Monitoring Building Vibration according to DIN4150-3/L3 (sensitive buildings).",
    "category": [
      "standard"
    ],
    "data-sources": [
      "rec19pIldKM58hQlD",
      "rech9XerLoitdmkRP",
      "rec2l1ben6EFobl73"
    ],
    "data_stream copy": "thp-2-vib, ecm-1-vib, ecv-1-vib",
    "_id": "recmEz2FjDx3yyEvK"
  },
  {
    "slug": "sd:vib-level-monitoring",
    "tags": [
      "trigger:vibration"
    ],
    "i18n": "Monitoring Vibration thresholds for Warning and Alarms (actions vs stop work)",
    "category": [
      "standard"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "reco3oDXuWSWU6qPO",
      "recLV3zmV5oGx9ZYT",
      "rec19pIldKM58hQlD",
      "rech9XerLoitdmkRP",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib, ecm-1-vib",
    "_id": "recJw9zCGL5Nt5jL2"
  },
  {
    "slug": "site:construction-work",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Construction Work ",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu",
      "rec2l1ben6EFobl73"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst, ecv-1-vib",
    "_id": "recwdU85kAtePJEHf"
  },
  {
    "slug": "site:hospital",
    "tags": [
      "where:indoors"
    ],
    "i18n": "Hospital",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "rec2l1ben6EFobl73"
    ],
    "data_stream copy": "ecv-1-vib",
    "_id": "recImaw6uQJeuInq8"
  },
  {
    "slug": "site:museum",
    "tags": [
      "where:indoors"
    ],
    "i18n": "Museum",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "rech9XerLoitdmkRP",
      "recOLVd6kEzXhNhBu"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "recqw9voDDq7e9dLX"
  },
  {
    "slug": "site:quarry",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Quarry",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recWGjntBIfHUKuuU"
  },
  {
    "slug": "site:rails",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Rails",
    "category": [
      "environnement"
    ],
    "_id": "recoNmHkJmxjpMdcc"
  },
  {
    "slug": "site:recreation-park",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Recreation Park",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "recvl7jdalep3Cw7u"
    ],
    "data_stream copy": "wnl-3-tmd",
    "_id": "recCs4x0LYnUZUTo1"
  },
  {
    "slug": "trigger:quarry-blasting",
    "i18n": "Quarry Blasting",
    "category": [
      "trigger"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recERx3ECNc8ToSKm",
      "recFJoSnlbArdFIVp",
      "rec9AgBsXNRy5DdtD",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5",
      "recDd8fgyT8423xbq"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recvi6K7hjyHVXte5"
  },
  {
    "slug": "unit:mm/s",
    "tags": [
      "type:time-series"
    ],
    "i18n": "Millimeters per second",
    "category": [
      "tlm-unit"
    ],
    "data-sources": [
      "rec3qPv4HciTjEOHr",
      "recFJoSnlbArdFIVp",
      "reccR5SJ0fwvkpWS1",
      "recWlZLp0RGsQigc5"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib",
    "_id": "recwCTLr02Utv6VSP"
  },
  {
    "slug": "unit:Pa",
    "tags": [
      "type:time-series"
    ],
    "i18n": "Pascal",
    "category": [
      "tlm-unit"
    ],
    "data-sources": [
      "recERx3ECNc8ToSKm",
      "rec9AgBsXNRy5DdtD"
    ],
    "data_stream copy": "lho-2-air, demo-2-air",
    "_id": "recl8J4Cdxwt3IEdH"
  },
  {
    "slug": "monitor:structural",
    "i18n": "Structural Monitoring",
    "category": [
      "domain"
    ],
    "_id": "recjtdAW96yoZlWX2"
  },
  {
    "slug": "tlm:1-per-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "periodic",
    "category": [
      "tlm-message-type"
    ],
    "_id": "rec9AuffqQntx3eYH"
  },
  {
    "slug": "tlm:2-thr-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "threshold",
    "category": [
      "tlm-message-type"
    ],
    "_id": "rechMyh22kdqiGOBe"
  },
  {
    "slug": "tlm:3-alt-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "alert",
    "category": [
      "tlm-message-type"
    ],
    "_id": "recLM8QSZhAt6u2Au"
  },
  {
    "slug": "tlm:4-sta-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "stats",
    "category": [
      "tlm-message-type"
    ],
    "_id": "recn8NOVROPatnuXB"
  },
  {
    "slug": "ingest:vib",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recX6ecCszJBfpboc"
  },
  {
    "slug": "ingest:retroVib",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recg4FfWMvZPG2LF2"
  },
  {
    "slug": "ingest:air",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recRB4xanrrTS6JUH"
  },
  {
    "slug": "ingest:coldpath",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recFwDduhmtg1LKAi"
  }
]
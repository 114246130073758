export const table = [
  {
    "role_type": "root",
    "accounts": [
      "recYRQLa8OahugnWk",
      "recfWZlD20h0Oa87b",
      "recmC6Myr7pAwgeRf",
      "recCIrv3sC97StyD5",
      "recpAhjZQFZOYATqM",
      "recv4Gzbi9mK9iW3L",
      "recVVhEuxlccS3erz",
      "rec9I1aHgfQFuhC3k",
      "recH2V21O1HvxIqhm",
      "recAJrQvc1lVBC2bG",
      "recm0sNGrHnExoJg6"
    ],
    "affiliation": [
      "recQd20E4mOk2ZLfj"
    ],
    "permissions": [
      "crud-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recLhnEbhncBQj5fA"
    ],
    "slug": "micromega__root",
    "_id": "recXRlHaY9YzIQxRB"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recU4d0NsafVSRELQ",
      "rec9uiUAqY0iLEp6b",
      "recdu0X9monxBgioQ",
      "recbSgLoxGp1D6wl6",
      "recM8HnNzCfeWJYey"
    ],
    "affiliation": [
      "recogUTMzKIM1jpsZ"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "reckRR73LtcRDabZW"
    ],
    "slug": "modyva__affiliate-admin",
    "_id": "recPBUdz726z0L5up"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recU4d0NsafVSRELQ"
    ],
    "affiliation": [
      "recIr4ScgUGTHkn9T"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "recP5J5z1roKrDmE4"
    ],
    "slug": "lhoist__account-admin",
    "_id": "recoo5j79K6YnGNAu"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "rec9I1aHgfQFuhC3k"
    ],
    "affiliation": [
      "recy8eOftihYfh4ik"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "recBFifocSq4fCSD1"
    ],
    "slug": "demo__account-admin",
    "_id": "recSdWBzCf0jP2Jft"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "rec9uiUAqY0iLEp6b"
    ],
    "affiliation": [
      "rec4V3mUhhEAluXDF"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "galere__account-admin",
    "_id": "recMOLapjHAuuuj8E"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recdu0X9monxBgioQ"
    ],
    "affiliation": [
      "recFJWeYJ3NYVzrKf"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "thomas-piron__account-admin",
    "_id": "rec4et38oeHtetAqm"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recbSgLoxGp1D6wl6"
    ],
    "affiliation": [
      "rec1uPMZbQ1CcmTGs"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "euro-com-museum__account-admin",
    "_id": "recWTzWWRfh7PiMLn"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recM8HnNzCfeWJYey"
    ],
    "affiliation": [
      "recog9FABvr6h923z"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "edith-cavell-hospital__account-admin",
    "_id": "recka6aGkwEtr88Ef"
  },
  {
    "role_type": "resident",
    "accounts": [
      "recU4d0NsafVSRELQ"
    ],
    "affiliation": [
      "recIr4ScgUGTHkn9T"
    ],
    "permissions": [
      "_r__-account",
      "crud-own"
    ],
    "slug": "lhoist__resident",
    "_id": "rec1qsN0Xi917oT74"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "receRH648GeIdtaZG"
    ],
    "affiliation": [
      "rechm9puuk9KRRaLF"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "walibi-holland__account-admin",
    "_id": "recH8ClrAsmxlTNAT"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "receRH648GeIdtaZG",
      "recpHSce5SHT2bE5Z"
    ],
    "affiliation": [
      "rec02ACNtFPx25pOe"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recyxxyvvx2iTnCjc"
    ],
    "slug": "flow-engineering__affiliate-admin",
    "_id": "recthVkzxRKuhuURV"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recpHSce5SHT2bE5Z"
    ],
    "affiliation": [
      "recpdOccwAFkEtuzl"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "flagpole__account-admin",
    "_id": "recn1lpeIPWj6Wmtz"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recESV0WYKfUUesjk",
      "recv4Gzbi9mK9iW3L",
      "recVVhEuxlccS3erz"
    ],
    "affiliation": [
      "recfQBlyumJnLtmpZ"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recb2AZelansV8RDx"
    ],
    "slug": "gantrex__affiliate-admin",
    "_id": "recY9m1v4Y63e8S2b"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recESV0WYKfUUesjk"
    ],
    "affiliation": [
      "recwpcSsxFvMc61hv"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "melbourne-heaven__account-admin",
    "_id": "reccruEnh4NPkTY4y"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recv4Gzbi9mK9iW3L",
      "recVVhEuxlccS3erz"
    ],
    "affiliation": [
      "recfQBlyumJnLtmpZ"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "gantrex__account-admin",
    "_id": "recSlH4gqMwUVJtWw"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recYRQLa8OahugnWk"
    ],
    "affiliation": [
      "recHlQ49LSyTN9FIB"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "recLhnEbhncBQj5fA",
      "rec05RruWRMH1hRcV"
    ],
    "slug": "pommeroeul__account-admin",
    "_id": "rectzg796uRxA5cwe"
  },
  {
    "role_type": "alert_sms_recipient",
    "accounts": [
      "recU4d0NsafVSRELQ"
    ],
    "affiliation": [
      "recIr4ScgUGTHkn9T"
    ],
    "users": [
      "recuBGyli9OHQck0n"
    ],
    "slug": "lhoist__alert_sms_recipient",
    "_id": "recQr4jMHjWenG7Un"
  },
  {
    "role_type": "alert_email_recipient",
    "accounts": [
      "recU4d0NsafVSRELQ"
    ],
    "affiliation": [
      "recIr4ScgUGTHkn9T"
    ],
    "users": [
      "recuBGyli9OHQck0n"
    ],
    "slug": "lhoist__alert_email_recipient",
    "_id": "recWarXxEtjfVOOid"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recXY1CQroQC0NgUU"
    ],
    "affiliation": [
      "recm4Kig9gxBeKcVx"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recUzJbaamSrvyw3W"
    ],
    "slug": "cementys__affiliate-admin",
    "_id": "rec8Mi25ePSpIMCLH"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "rec6FChHb2nNdRZTV",
      "recIRyhLwGVtUr1Ml"
    ],
    "affiliation": [
      "recDKrMIhw8TSACQl"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recZ2HZo8Rx44Cza4"
    ],
    "slug": "vmvtech__affiliate-admin",
    "_id": "recGxH6MSIpXrcFEQ"
  },
  {
    "role_type": "account-admin",
    "slug": "__account-admin",
    "_id": "rec4zOFhYFLW8Xe2o"
  }
]
import React, { PureComponent, Fragment } from 'react'
// Base
import { VBox, HBox, Text, VSpacer, HSpacer } from '@mmd/design-layout'
import { defaultTokens, Layout, Section, Title, WidgetBox } from './styled'
// Blocks
import Button from '@mmd/design-system/button'
import DateRangePicker from '@mmd/block/week-range-picker'
import Widget from '@mmd/block/widget/Widget'
import Events from '@mmd/block-recovib/telemetry-event-table'
import Bursts from '@mmd/block-recovib/telemetry-burst-table'
import BurstSummary from '@mmd/block-recovib/telemetry-burst--explorer'
import { streamConfig } from '@mmd/domain-recovib/stream/configs'
import { startOfIsoWeek, enumerateDaysBetweenDates } from '@mmd/fns/datetime/momentual' // Base

class MonitorStat extends PureComponent {
	constructor(props) {
		super(props)
		const { whenFetchStreams } = this.props
		this.state = {
			burstSummary: undefined,
			cachedSummaries: undefined,
			showAll: true,
		}
		this.bound = {
			onLoadTimeRange: (event) => {
				const { streams } = this.props
				if (typeof whenFetchStreams === 'function') {
					whenFetchStreams(streams)
				}
				this.setState({ burstSummary: undefined })
			},
			onToggleShowAll: (event) => {
				this.setState({ showAll: !this.state.showAll })
			},
			whenBurstAction: (action) => {
				const { type, payload } = action
				const { hasSafeLevels } = this.props
				if (type === 'BURST_SUMMARY') {
					this.setState({ burstSummary: undefined }, () => {
						console.log('[burstSummary]', hasSafeLevels)
						payload.showApex = hasSafeLevels
						this.setState({ burstSummary: payload })
					})
				}
			},
		}
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.timeRange !== prevProps.timeRange) {
			const { timeRange } = this.props
			const days = enumerateDaysBetweenDates(timeRange.start, timeRange.end)
			const weekStart = startOfIsoWeek(timeRange.start)
			const quarter = weekStart.quarter()
			const year = weekStart.year()
			this.setState({
				days: {
					weekStart: weekStart.format('YYYY-MM-DD'),
					days,
					quarter,
					year,
				},
			})
		}
	}

	render() {
		const { timeRange } = this.props
		const { title, streams, streamsData, config, burstTableName, startDate } = this.props
		const { whenTimeRangeChange, whenExit } = this.props
		const { fetchEventListing, fetchBurstListing } = this.props
		const { getBurstUrl, streamUrl } = this.props
		const { tokens = defaultTokens } = this.props
		const { burstSummary, showAll, days } = this.state
		const { onToggleShowAll, onLoadTimeRange, whenBurstAction } = this.bound

		const { showEvents, showBursts, isNotLhoi, eventMin, eventUnit, eventLegend, burstTemporalTitle, burstTemporalLegend } = config

		return (
			<Layout>
				<HBox>
					<Button>
						{'<--'} <Text onClick={whenExit}>Back To Accounts</Text>
					</Button>
				</HBox>
				<VSpacer height={32} />
				<Section tokens={tokens}>
					<Title>{title}</Title>
					<VSpacer height={16} />
					<div>
						Archived data-streams:{' '}
						{streams.map((d) => {
							const { slug } = d
							return (
								<a key={slug} target="_blank" rel="noopener noreferrer" href={`${streamUrl}/tlm/`}>
									{slug}
								</a>
							)
						})}
					</div>
				</Section>
				<VSpacer height={32} />
				<Section tokens={tokens}>
					<HBox>
						<Button primary tokens={null} onClick={onLoadTimeRange}>
							Load Data
						</Button>
						<HSpacer width={16} />
						<VBox>
							<DateRangePicker whenChange={whenTimeRangeChange} timeRange={timeRange} firstDate={startDate} showWeeks={isNotLhoi} />
						</VBox>
					</HBox>
					<VSpacer height={8} />

					{(streams || []).map((d, i, arr) => {
						let { slug, WidgetFactory, ...meta } = d
						meta = Object.assign({}, meta, streamConfig(slug))

						const streamData = streamsData[slug]
						if (!streamData) {
							return <Fragment key={i}>-- Data will show here once loaded --</Fragment>
						} else {
							return arr.length ? (
								<WidgetBox key={i}>
									<Widget data={streamData} meta={{ slug, ...meta }} Factory={WidgetFactory} />
								</WidgetBox>
							) : null
						}
					})}
				</Section>
				{(showEvents || showBursts) && (
					<Section maxHeight={600}>
						<Button onClick={onToggleShowAll}>{showAll ? 'Show Selected Period' : 'Show All'}</Button>
						<HBox>
							{showEvents && (
								<Events
									days={days && days.days}
									showAll={showAll}
									fetchEventListing={fetchEventListing}
									eventMin={eventMin}
									unit={eventUnit}
									legend={eventLegend}
								/>
							)}
							{showBursts && (
								<Bursts
									days={days && days.days}
									showAll={showAll}
									whenAction={whenBurstAction}
									fetchBurstListing={fetchBurstListing}
									getBurstUrl={getBurstUrl}
								/>
							)}
						</HBox>
					</Section>
				)}

				{burstSummary && (
					<Section>
						<Title>Bursts Details</Title>
						<BurstSummary
							burstSummary={burstSummary}
							tableName={burstTableName}
							burstTemporalTitle={burstTemporalTitle}
							burstTemporalLegend={burstTemporalLegend}
						/>
					</Section>
				)}
			</Layout>
		)
	}
}

MonitorStat.propTypes = {}

export default MonitorStat

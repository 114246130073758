// https://docs.microsoft.com/en-us/rest/api/cosmos-db/querying-cosmosdb-resources-using-the-rest-api
import { encryptBase64 } from '@mmd/fns/base64/crypto-encrypt'

/**
 *
 * getText
 * expected:
 * ```
 * post
 * docs
 * dbs/db-main/colls/blastinghistory
 * thu, 19 sep 2019 16:07:32 gmt
 * ```
 */
export const getDocsStampedText = ({ timestamp, type, link, method }) => {
  return `${method || ''}\n${type || ''}\n${link || ''}\n${timestamp || ''}\n\n`.toLowerCase()
}

export const encryptRequest = ({ date, mastKey, link, method, type }) => {
  // :WARN: check that this is the timestamp that we actually want for users a few timezones away
  const timestamp = new Date(date).toUTCString()
  const text = getDocsStampedText({ timestamp, link, method, type })
  const base64Bits = encryptBase64({ text, mastKey })
  const signature = encodeURIComponent(`type=master&ver=1.0&sig=${base64Bits}`)
  return { method, link, signature, timestamp }
}

const { method, type } = { method: 'POST', type: 'docs' }

export const fetchFormattedData = ({ query, cosmosStore }, { whenSuccess, whenError }) => {
  if (!query || !cosmosStore) {
    return
  }
  const { host, port = '443', db, collection, mastKey, partitionKey = '["99"]', itemCount = 10000000 } = cosmosStore
  const link = `dbs/${db}/colls/${collection}`
  const { signature, timestamp } = encryptRequest({ date: Date.now(), mastKey, method, type, link })
  const request = {
    url: `https://${host}.documents.azure.com:${port}/${link}/docs`,
    method: method,
    timeout: 0,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/query+json',
      'x-ms-version': '2018-12-31',
      Authorization: signature,
      'x-ms-date': timestamp,
      'x-ms-max-item-count': itemCount !== -1 ? itemCount : undefined,
      'x-ms-documentdb-populatequerymetrics': 'true',
      'x-ms-documentdb-isquery': 'true',
      'x-ms-documentdb-query-enablecrosspartition': 'true',
      'x-ms-documentdb-partitionkey': partitionKey,
    },
    body: query ? JSON.stringify(query) : undefined,
  }

  if (!request) {
    whenError('We could not load the data')
    return
  }
  fetch(request.url, request)
    .then((res) => {
      if (!res.ok) {
        whenError(`NOTOK - error ${JSON.stringify(res)}`)
      }
      return res.json()
    })
    .then((json) => {
      whenSuccess(json.Documents)
    })
    .catch((error) => {
      whenError(error)
    })
}
